import { useRef, useState } from "react"
import styled from "styled-components"

import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  shift,
  size,
  useFloating,
  useInteractions,
  useListNavigation,
} from "@floating-ui/react"
import { useDismiss, useRole } from "@floating-ui/react"
import { useDebounceCallback } from "usehooks-ts"

import { cardBoxShadow } from "src/constants/shadows"
import { colorsV2 } from "src/ui/colors"
import ChevronDown from "src/ui/icons/chevron-down-larger.svg"
import { TInputContainerProps } from "src/ui/InputContainer/InputContainer"
import { MText } from "src/ui/MText"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

type TOmittedInputContainerProps = Omit<
  TInputContainerProps,
  | "shrink"
  | "tabIndex"
  | "endAdornment"
  | "cursor"
  | "children"
  | "showClearButton"
  | "onClear"
>

type TComboboxOption = {
  label: string
  value: string
  description?: string
  icon?: React.FC<React.SVGProps<SVGSVGElement>>
  selectedLabelText?: string
}

type TComboboxProps = {
  selectedValue: string
  options: TComboboxOption[]
  onChange: (value: string) => void
  onSearch: (input: string) => void
  required?: boolean
  searchDelay?: number
  initialInput?: string
} & TOmittedInputContainerProps

export function Combobox({
  label,
  startAdornment,
  selectedValue,
  options,
  onChange,
  onSearch,
  required,
  searchDelay = 500,
  initialInput,
}: TComboboxProps) {
  const [open, setOpen] = useState(false)
  // This is the current virtually focused/hovered item in the list
  const [activeIndex, setActiveIndex] = useState(0)
  const [input, setInput] = useState(initialInput ?? "")

  const selectedIndex = options.findIndex(
    (option) => option.value === selectedValue
  )

  const listRef = useRef<HTMLElement[]>([])

  const debouncedSearch = useDebounceCallback((value: string) => {
    onSearch(value)
  }, searchDelay)

  const floating = useFloating<HTMLDivElement>({
    open,
    // This handles only open changed events triggered by floating-ui
    onOpenChange: (open) => {
      if (open) {
        setActiveIndex(selectedIndex)
      } else {
        if (!selectedValue) {
          setInput("")
        }

        onSearch("")
      }

      setOpen(open)
    },
    middleware: [
      offset(8),
      flip(),
      shift(),
      size({
        apply: ({ rects, elements }) => {
          elements.floating.style.width = `${rects.reference.width}px`
        },
      }),
    ],
    transform: true,
    whileElementsMounted: autoUpdate,
  })

  const dismiss = useDismiss(floating.context)
  const role = useRole(floating.context, {
    role: "combobox",
  })

  const listNav = useListNavigation(floating.context, {
    activeIndex,
    selectedIndex,
    listRef,
    onNavigate: (index) => {
      if (index !== null) {
        setActiveIndex(index)
      }
    },
    virtual: true,
    loop: true,
    scrollItemIntoView: true,
  })

  const interactions = useInteractions([dismiss, role, listNav])

  function handleSelect() {
    const selectedOption = options[activeIndex]

    setInput(selectedOption.selectedLabelText ?? selectedOption.label)
    onChange(selectedOption.value)
    setOpen(false)
  }

  return (
    <div>
      <div
        ref={floating.refs.setReference}
        {...interactions.getReferenceProps()}
      >
        <MTextField
          label={label}
          value={input}
          onChange={(value) => {
            setActiveIndex(0)
            setInput(value)
            debouncedSearch(value)
            onChange("")
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault()
              handleSelect()
            } else {
              setOpen(true)
            }
          }}
          startAdornment={startAdornment}
          endAdornment={<ChevronDown width={16} color="unset" />}
          required={required}
          onClick={() => {
            setOpen((prev) => !prev)
          }}
        />
      </div>
      <FloatingPortal>
        {open && options.length > 0 && (
          <FloatingFocusManager
            context={floating.context}
            initialFocus={-1}
            modal={false}
          >
            <PopoverContent
              ref={floating.refs.setFloating}
              style={floating.floatingStyles}
              {...interactions.getFloatingProps()}
            >
              <div>
                {options.map((option, index) => (
                  <ComboboxItem
                    key={option.value}
                    {...interactions.getItemProps({
                      ref: (node) => {
                        if (listRef.current && node) {
                          listRef.current[index] = node
                        }
                      },
                      onClick: handleSelect,
                      active: index === activeIndex,
                      selected: index === selectedIndex,
                    })}
                    $active={index === activeIndex}
                    $selected={index === selectedIndex}
                  >
                    {option.icon && (
                      <div>
                        <option.icon width={24} />
                      </div>
                    )}
                    <div>
                      {option.label}
                      {option.description && (
                        <MText variant="bodyS" color="secondary">
                          {option.description}
                        </MText>
                      )}
                    </div>
                  </ComboboxItem>
                ))}
              </div>
            </PopoverContent>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </div>
  )
}

const ComboboxItem = styled.div<{ $active: boolean; $selected: boolean }>`
  display: flex;
  gap: ${spacing.XS};
  align-items: center;

  cursor: pointer;
  padding: ${spacing.M};
  background-color: ${({ $active, $selected }) => {
    if ($selected) {
      return colorsV2.neutralDark
    }

    if ($active) {
      return colorsV2.neutral
    }

    return colorsV2.neutralLight
  }};
`

const PopoverContent = styled.div`
  max-height: 200px;
  overflow-y: auto;
  background-color: ${colorsV2.neutralLight};
  border: 1px solid ${colorsV2.divider};
  border-radius: 0.5rem;
  /*
    MUI creates a stacking context for it's floating label which makes the popover render behind it, this makes sure the popover stacking context renders above
  */
  z-index: 10;
  ${cardBoxShadow}
`
